export const initalMixin = {
    data() {
        return {
            tableSelectNum: 0,
            table: {
                talbeColumns: [
                    {
                        type: 'selection',
                        width: 60,
                        align: 'center'
                    },

                    {
                        key: 'zoneName',
                        align: 'center',
                        title: '区',
                        width: 150,
                    },
                    {
                        key: 'streetName',
                        align: 'center',
                        title: '街镇',
                        width: 150,
                    },
                    {
                        key: 'communityName',
                        align: 'center',
                        title: '社区',
                        width: 150,
                    },
                    {
                        key: 'residents',
                        width: 150,
                        align: 'center',
                        title: '社区居民名册',
                        render: (h, params) => {
                            return h('div', {
                                style: {
                                    color: params.row.residents == 1 ? 'blue' : 'red',
                                    textDecoration: params.row.residents == 1 ? 'underline' : '',
                                    cursor: 'pointer'
                                },
                                on: {
                                    click: () => {
                                        if (params.row.residents == 1) {
                                            this.$core.openLayerFrame({
                                                type: 2,
                                                title: '初始社区架构',
                                                content: `/page#/residentchildindexlw?name=${params.row.addressName}&orgCode=${params.row.orgCode}`,
                                                area: ['100%', '100%']
                                            }, () => {
                                                this.table.page = this.table.currentPage
                                                this.getList()
                                            })
                                        }
                                    },
                                },
                            }, params.row.residents == 1 ? '已导入' : '未导入')
                        }
                    },
                    {
                        key: 'party',
                        width: 150,
                        align: 'center',
                        title: '网格党支部',
                        render: (h, params) => {
                            return h('div', {
                                style: {
                                    color: params.row.party == 1 ? 'blue' : 'red',
                                    textDecoration: params.row.party == 1 ? 'underline' : '',
                                    cursor: 'pointer'
                                },
                                on: {
                                    click: () => {
                                        if (params.row.party == 1) {
                                            this.$core.openLayerFrame({
                                                type: 2,
                                                title: '初始网格党支部',
                                                content: `/page#/gridpartyindexlw?name=${params.row.addressName}&orgCode=${params.row.orgCode}`,
                                                area: ['100%', '100%']
                                            })
                                        }
                                    },
                                },
                            }, params.row.party == 1 ? '已导入' : '未导入')
                        }
                    },
                    {
                        key: 'group',
                        width: 150,
                        align: 'center',
                        title: '网格党小组',
                        render: (h, params) => {
                            return h('div', {
                                style: {
                                    color: params.row.group == 1 ? 'blue' : 'red',
                                    textDecoration: params.row.group == 1 ? 'underline' : '',
                                    cursor: 'pointer'
                                },
                                on: {
                                    click: () => {
                                        if (params.row.group == 1) {
                                            this.$core.openLayerFrame({
                                                type: 2,
                                                title: '初始网格（楼栋）党小组',
                                                content: `/page#/gridgroupindexlw?name=${params.row.addressName}&orgCode=${params.row.orgCode}`,
                                                area: ['100%', '100%']
                                            })
                                        }
                                    },
                                },
                            }, params.row.group == 1 ? '已导入' : '未导入')
                        }
                    },
                    {
                        key: '',
                        align: 'center',
                        title: '初始化进度',
                        minWidth: 750,
                        render: (h, params) => {
                            return h('div', [
                                h('Steps',
                                    {
                                        props: {
                                            current: params.row.archiveStatus == 1 ? 1 : params.row.communityStatus == 1 ? 0 : ''
                                        },
                                        style: {
                                            display: 'inline-block',
                                            width: '300px'
                                        },
                                    },
                                    [
                                        h('Step',
                                            {
                                                props: { title: '初始社区架构' },
                                                style: {
                                                    cursor: 'pointer'
                                                },
                                                nativeOn: {
                                                    click: () => {
                                                        this.$core.openLayerFrame({
                                                            type: 2,
                                                            title: '社区架构初始化拆分',
                                                            content: `/page#/communityinitialilw?name=${params.row.addressName}&orgCode=${params.row.orgCode}`,
                                                            area: ['100%', '100%']
                                                        })
                                                    },
                                                },
                                            },

                                        ),
                                        h('Step',
                                            {
                                                props: { title: '初始民情档案' },
                                                style: {
                                                    cursor: 'pointer'
                                                },
                                                nativeOn: {
                                                    click: () => {
                                                        this.$core.openLayerFrame({
                                                            type: 2,
                                                            title: '民情档案初始化拆分',
                                                            content: `/page#/civilinitializationlw?name=${params.row.addressName}&orgCode=${params.row.orgCode}`,
                                                            area: ['100%', '100%']
                                                        })
                                                    },
                                                },
                                            }
                                        ),


                                    ]),

                                h('Steps',
                                    {
                                        props: {
                                            current: params.row.gridStatus == 1 ? 1 : params.row.buildingStatus == 1 ? 0 : ''
                                        },
                                        style: {
                                            display: 'inline-block',
                                            width: '400px'
                                        },
                                    },
                                    [
                                        h('Step',
                                            {
                                                props: { title: '初始网格（楼栋）党小组' },
                                                style: {
                                                    cursor: 'pointer'
                                                },
                                                nativeOn: {
                                                    click: () => {
                                                        this.$core.openLayerFrame({
                                                            type: 2,
                                                            title: '网格（楼栋）党小组初始化',
                                                            content: `/page#/gridgroupindexlw?name=${params.row.addressName}&orgCode=${params.row.orgCode}`,
                                                            area: ['100%', '100%']
                                                        })
                                                    },
                                                },
                                            }
                                        ),
                                        h('Step',
                                            {
                                                props: { title: '初始网格党支部' },
                                                style: {
                                                    cursor: 'pointer'
                                                },
                                                nativeOn: {
                                                    click: () => {
                                                        this.$core.openLayerFrame({
                                                            type: 2,
                                                            title: '网格党支部初始化',
                                                            content: `/page#/gridpartyindexlw?name=${params.row.addressName}&orgCode=${params.row.orgCode}`,
                                                            area: ['100%', '100%']
                                                        })
                                                    },
                                                },
                                            }
                                        ),



                                    ]),


                            ])
                        }
                    },
                    // {
                    //     fixed: 'right',
                    //     title: "操作",
                    //     width: 180,
                    //     align: "center",
                    //     render: (h, params) => {
                    //         return h("div", [
                    //             h(
                    //                 "Button",
                    //                 {
                    //                     props: {
                    //                         type: "info",
                    //                         size: "small",
                    //                     },
                    //                     on: {
                    //                         click: () => {
                    //                             this.initializeAlbum(params.row)
                    //                         },
                    //                     },
                    //                 },
                    //                 "初始化底册数据"
                    //             ),
                    //         ]);
                    //     },
                    // },
                ],





                tableData: [],
                loading: false,
                page: 1,
                total: 0,
                pageSize: 100,
                currentPage: 1,


            },
        }
    },
    methods: {
        // 接口部分
        // 导出当前页数据
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                content:
                    "<p>您正在导出当前页的数据，是否继续？</p>",
                onOk: () => {
                    let tHeader = [
                        "区",
                        "街道",
                        "社区",
                        "社区居民名册",
                        "网格党支部",
                        "网格党小组"
                    ];
                    let filterVal = [
                        "zoneName",
                        "streetName",
                        "communityName",
                        "residentsName",
                        "partyName",
                        "groupName"
                    ];
                    let tableData = JSON.parse(JSON.stringify(this.table.tableData));
                    this.$core.exportExcel(tHeader, filterVal, tableData, "社区动员组织体系数据初始化");
                }

            })
        },
        // 方法部分
        getList() {
            this.table.loading = true
            try {
                this.$get('/syaa/pc/sy/user/gridManage/queryIndexTwoByPage', {
                    page: this.table.page,
                    pageSize: this.table.pageSize,
                    zoneCode: this.search.cascaderIdList[0] ? this.search.cascaderIdList[0] : '',
                    streetCode: this.search.cascaderIdList[1] ? this.search.cascaderIdList[1] : '',
                    orgCode: this.search.cascaderIdList[2] ? this.search.cascaderIdList[2] : '',
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    oemCode: parent.vue.oemInfo.oemCode
                }).then(res => {
                    this.table.loading = false
                    if (res && res.code && res.code == 200) {
                        if (res.dataList && res.dataList.length) {
                            res.dataList.map(item => {
                                item.addressName = item.zoneName + '-' + item.streetName + '-' + item.communityName
                                item.residentsName = item.residents == 1 ? '已导入' : '未导入'
                                item.partyName = item.party == 1 ? '已导入' : '未导入'
                                item.groupName = item.group == 1 ? '已导入' : '未导入'
                                item._disabled = item.communityStatus == 1 && item.archiveStatus != 1 ? false : true
                            })
                        }
                        this.table.tableData = res.dataList
                        this.table.total = res.maxCount
                        this.table.currentPage = res.currentPage
                    } else {
                        this.$Message.error({
                            content: '获取数据失败',
                            background: true
                        })
                        return
                    }
                })
            } catch (e) {
                this.table.loading = false
                this.$Message.error({
                    content: '获取数据失败',
                    background: true
                })
                return
            }

        },
        //选中
        tableSelect(val) {
            console.log(val)
            this.orgList = []
            this.orgName = ''
            let nameArr = []
            if (val.length != 0) {
                val.map(item => {
                    nameArr.push(item.communityName)
                    this.orgList.push(item.orgCode)
                })
            }
            this.orgName = nameArr.join(',')
            console.log(this.orgName)
            console.log(this.orgList)
            this.tableSelectNum = val.length
        },
        // 批量同步
        synchro() {
            if (this.orgList.length == 0) {
                this.$Message.error({
                    content: '请勾选社区',
                    background: true
                })
                return
            }
            this.$Modal.confirm({
                title: "温馨提示",
                content: `<p>确定同步<span style="color:red">${this.orgName}</span></p>`,
                okText: "确定",
                cancelText: "取消",
                loading: true,
                onOk: () => {
                    this.$post('/syaa/pc/sy/user/gridManage/importByOrgCodes', {
                        orgCodeList: this.orgList.join(',')
                    }).then(res => {
                        this.$Modal.remove();
                        if (res.code == 200) {
                            this.synchroLoading = true
                            this.$Message.success({
                                content: '操作成功,请一小时后查看',
                                background: true
                            })
                            this.orgName = ''
                            this.orgList = []
                            this.getList()
                            return
                        } else {
                            this.$Message.error({
                                content: res.desc,
                                background: true
                            })
                        }
                    })
                },
            });
        },
        //初始化底册数据
        initializeAlbum(data) {
            if (this.tableSelectNum == 0) {
                this.$Message.warning({
                    content: '请勾选数据',
                    background: true
                })
                return
            }
            this.$Modal.confirm({
                title: "温馨提示",
                content:
                    "<p>是否初始化当前勾选的社区同步至居民数据库</p>",
                onOk: () => {

                    this.$Message.loading({
                        content: "初始化中...",
                        duration: 0,
                    });
                    this.orgList.map((e, index) => {
                        this.syncOriginal(e, index)
                    })
                }
            })
        },
        syncOriginal(orgCode, index) {
            // this.$post('/syaa/pc/sy/user/gridManage/syncOriginal',{
            this.$post('/gateway/api/lfdata/DataSyncController/sync3TBToBaseByOrgCode', {
                communityCode:orgCode,
            }).then(res => {
                if (index == this.orgList.length - 1) {
                    this.$Message.destroy();
                    this.$Message.success({
                        content: '同步至居民数据库成功',
                        background: true
                    })
                    this.getList()
                    this.tableSelectNum = 0
                }
            })
        }

    }
}